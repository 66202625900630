// Vue.JS
import Vue from 'vue'
import App from './App.vue'

// CEESAR
import ceesarCore from '../../ceesar/core';
import ceesarDesign from '../../ceesar/design';
import {CeesarRouter} from '../../ceesar/router';

// SETUP
import routerSetup from '../../ceesar/setup/router';
import initOptions from '../../ceesar/setup/keycloak';

let ceesarRouter = new CeesarRouter(routerSetup);
ceesarRouter.registerService("keycloak", initOptions);

Vue.config.productionTip = false

Vue.use(ceesarCore);
Vue.use(ceesarDesign);

Vue.use(ceesarRouter);

ceesarRouter.startApplication(process.env.VUE_APP_KEYCLOAK_CLIENTID_PORTAL, () => {
  new Vue({
    render: h => h(App)
  }).$mount('#app');
});
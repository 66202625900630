import Vue from 'vue'

const eventHub = new Vue();

class ceesarCore
{
    // Event management
    #eventHub = eventHub;
    get eventHub(){ return this.#eventHub;}
    
    // Data transimission
    #transmitHash = {};
    setTransmitData(pKey, pData = null)
    {
        this.#transmitHash[pKey] = pData;
    }
    getTransmitData(pKey){
        return this.#transmitHash[pKey];
    }

    // Meta
    get name(){
        return "CEESAR-Core";
    }
    get description()
    {
        return "CEESAR vue.js core framework";
    }

    // CEESAR framework registration
    #registration = [];
    register(pFramework){
        this.#registration.push(pFramework);
    }

    install(pVue, pOptions) 
    {
        pVue.prototype.$ceesar = this;
    }
}

export default new ceesarCore();
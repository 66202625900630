<template>
  <CEESAR-Application>
    <CEESAR-Main>
        <CEESAR-View title="Access Denied">
            <CEESAR-MessageLabel format="Information">
                Sorry...<br>
                <br>
                You are not allowed to access this application.<br>
                Please contact CEESAR Administrator.
            </CEESAR-MessageLabel>
            <CEESAR-Button label="Sign in" v-on:click="logOut"/>
        </CEESAR-View>
    </CEESAR-Main>
  </CEESAR-Application>
</template>

<script>
export default {
  name:"CEESAR-AccessDenied",
  async mounted()
  {
    this.$ceesar.design.applyTheme(document.querySelector('html'), "public");
  },
  methods:
  {
    logOut(){
        this.$ceesar.router.logout();
    }
  }
}
</script>
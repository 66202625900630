let initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: undefined,
  onLoad: 'login-required'
};

if (process.env.NODE_ENV === 'production')
{
    // Ici, les URLs de production...
}


export default initOptions;
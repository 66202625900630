import Vue from 'vue'

import './assets/ceesar.design.less';

class ceesarDesign
{
    // Colors 
    applyTheme(pHtml, pTheme)
    {
        pHtml.dataset.theme = `theme-${pTheme}`;
    }

    // Event management
    #eventHub = new Vue();

    registerEvent(pEvent, pHandle)
    {
        this.#eventHub.$on(pEvent, pHandle);
    }
    unregisterEvent(pEvent)
    {
        this.#eventHub.$off(pEvent);
    }

    // Toast
    registerShowToastEvent(pHandle)
    {
        this.registerEvent("ShowToast", pHandle);
    }
    unregisterShowToastEvent()
    {
        this.unregisterEvent("ShowToast");
    }
    showToast(pMessage, pType)
    {
        this.#eventHub.$emit("ShowToast", pMessage, pType);
    }

    // Dialog
    registerDialogEvent(pEvent, pHandle)
    {
        this.registerEvent(pEvent + "Dialog", pHandle);
    }
    unregisterDialogEvent(pEvent)
    {
        this.unregisterEvent(pEvent + "Dialog");
    }
    showDialog(pContentId)
    {
        this.#eventHub.$emit("ShowDialog", pContentId);
    }
    closeDialog()
    {
        this.#eventHub.$emit("CloseDialog");
    }

    registerConfirmEvent(pEvent, pHandle)
    {
        this.registerEvent(pEvent + "Confirm", pHandle);
    }
    unregisterConfirmEvent(pEvent)
    {
        this.unregisterEvent(pEvent + "Confirm");
    }
    showConfirm(pMessage, pButtons)
    {
        this.#eventHub.$emit("ShowConfirm", pMessage, pButtons);
    }
    answerDialog(pAnswer)
    {
        this.#eventHub.$emit("AnswerDialog", pAnswer);
    }

    // Meta
    get name(){
        return "CEESAR-Design";
    }
    get description()
    {
        return "CEESAR vue.js component framework";
    }


    install(pVue, pOptions) 
    {
        Vue.component("MaterialDesign-Icon", () => import("./components/Icon.vue"));

        Vue.component("CEESAR-ControlBox", () => import("./components/ControlBox.vue"));
        Vue.component("CEESAR-TextInputBox", () => import("./components/TextInputBox.vue"));
        Vue.component("CEESAR-ComboBox", () => import("./components/ComboBox.vue"));
        Vue.component("CEESAR-ComboBox-DropDown", () => import("./components/ComboBoxDropDown.vue"));
        Vue.component("CEESAR-CheckBox", () => import("./components/CheckBox.vue"));
        
        Vue.component("CEESAR-FileUpload", () => import("./components/FileUpload.vue"));
        
        Vue.component("CEESAR-Expandable", () => import("./components/Expandable.vue"));
        Vue.component("CEESAR-SideBar", () => import("./components/SideBar.vue"));

        Vue.component("CEESAR-Header", () => import("./components/Header.vue"));
        Vue.component("CEESAR-Main", () => import("./components/Main.vue"));
        Vue.component("CEESAR-Footer", () => import("./components/Footer.vue"));

        Vue.component("CEESAR-Link", () => import("./components/Link.vue"));
        Vue.component("CEESAR-MenuItem", () => import("./components/MenuItem.vue"));
        Vue.component("CEESAR-Button", () => import("./components/Button.vue"));

        Vue.component("CEESAR-DropDown", () => import("./components/DropDown.vue"));

        Vue.component("CEESAR-TableLine", () => import("./components/TableLine.vue"));
        Vue.component("CEESAR-Table", () => import("./components/Table.vue"));

        Vue.component("CEESAR-ModelTable", () => import("./components/ModelTable.vue"));

        Vue.component("CEESAR-Form", () => import("./components/Form.vue"));
        Vue.component("CEESAR-View", () => import("./components/View.vue"));
        Vue.component("CEESAR-Card", () => import("./components/Card.vue"));
        Vue.component("CEESAR-Dialog", () => import("./components/Dialog.vue"));

        Vue.component("CEESAR-MessageLabel", () => import("./components/MessageLabel.vue"));
        Vue.component("CEESAR-Toast", () => import("./components/Toast.vue"));
        Vue.component("CEESAR-DialogHost", () => import("./components/DialogHost.vue"));

        Vue.component("CEESAR-PortalGroup", () => import("./components/PortalGroup.vue"));
        Vue.component("CEESAR-PortalLink", () => import("./components/PortalLink.vue"));
        Vue.component("CEESAR-PortalView", () => import("./components/PortalView.vue"));

        Vue.component("CEESAR-ApplicationTitle", () => import("./components/ApplicationTitle.vue"));
        Vue.component("CEESAR-ApplicationLogo", () => import("./components/ApplicationLogo.vue"));
        Vue.component("CEESAR-Application", () => import("./components/Application.vue"));


        Vue.directive('on-click-outside', {
            bind: function (el, binding, vnode)
            {
                el.clickOutsideEvent = (event) => {
                  if (!(el == event.target || el.contains(event.target))) 
                  {
                    vnode.context[binding.expression](event);
                  }
                };
                document.body.addEventListener('click', el.clickOutsideEvent)
              },
              unbind: function (el)
              {
                document.body.removeEventListener('click', el.clickOutsideEvent)
              },
          });

        pVue.prototype.$ceesar.design = this;
    }
}

export default new ceesarDesign();

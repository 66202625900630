import Vue from 'vue';

import { serviceManager } from './models/services';

class CeesarRouter
{
    // Properties
    #groups = [];
    get groups() { return this.#groups; }

    // Applications
    #applications = [];
    getVisibleGroups()
    {
        let groups = [];
        this.#groups.forEach(group => {
            if(!group.isHidden)
            {
                let newGroup = group;
                let applications = group.applications;
                newGroup.clearApplications();

                applications.forEach(application =>{

                    if(!application.isHidden)
                    {
                        newGroup.addApplication(application);
                    }
                });

                if(newGroup.applicationCount > 0)
                {
                    groups.push(newGroup);
                }
            }
        });
        return groups;
    }


    // Services
    #serviceManager = undefined;
    registerService(pAuth, pOptions = undefined)
    {
        this.#serviceManager.registerService(pAuth, pOptions);
    }
    startApplication(pClientId, pHandle)
    {
        this.#serviceManager.start(pClientId, pHandle);
    }

    // User related
    getUserName()
    {
        return this.#serviceManager.getUserName();
    }
    getUserFullName()
    {
        return this.#serviceManager.getUserFullName();
    }
    getUserMenuOptions()
    {
        return this.#serviceManager.getUserMenuOptions();
    }
    getCustomAttribute(pName)
    {
        return this.#serviceManager.getCustomAttribute(pName);
    }
    logout()
    {
        this.#serviceManager.logout();
    }

    // Application
    getApplicationUrl(pClientId)
    {
        return this.#serviceManager.getApplicationUrl(pClientId);
    }

    // Meta
    get name(){
        return "CEESAR-Router";
    }
    get description()
    {
        return "CEESAR vue.js Router framework";
    }

    // Vue
    install(pVue, pOptions) 
    {
        Vue.component("CEESAR-Auth-UserDropDown", () => import("./components/UserDropDown.vue"));

        pVue.prototype.$ceesar.router = this;
    }

    // Identity
    constructor(pGroups)
    {
        let withDummy = process.env.NODE_ENV != 'production';

        pGroups.forEach(group => {

            if(!group.isDummy || withDummy)
            {
                let newGroup = group;
                let applications = group.applications;
                newGroup.clearApplications();
                this.#groups.push(newGroup);

                applications.forEach(application =>{

                    if(!application.isDummy || withDummy)
                    {
                        newGroup.addApplication(application);
                        this.#applications.push(application);
                    }
                });
            }
        });

        this.#serviceManager = new serviceManager(this.#applications);

        /*
        this.#serviceManager.registerService("test");
        this.#serviceManager.registerService("keycloak");
        this.#serviceManager.registerService("limesurvey");
        this.#serviceManager.registerService("limesurveyAdmin");
        */
    }
}

export { CeesarRouter };
class routerGroup
{
    // Properties
    #label = undefined;
    get label(){ return this.#label; }
    #icon = undefined;
    get icon(){ return this.#icon; }
    #isHidden = false;
    hide(){this.#isHidden = true; }
    get isHidden(){ return this.#isHidden; }
    #isDummy = false;
    get isDummy(){ return this.#isDummy; }

    // Applications
    #applications = [];
    get applications(){ return this.#applications; }
    get applicationCount(){ return this.#applications == undefined? 0 : this.#applications.length; }

    clearApplications()
    {
        this.#applications = [];
    }
    createApplication(pAuth, pClientId, pLabel, pUrl, pIcon = undefined, pIsHidden = false, pIsDummy = false)
    {
        let application = new routerApplication(pAuth, pClientId, pLabel, pUrl, pIcon, pIsHidden, pIsDummy);
        this.addApplication(application);
    }
    addApplication(pApplication)
    {
        this.#applications.push(pApplication);
    }

    // Identity
    constructor(pLabel, pIcon, pIsHidden = false, pIsDummy = false)
    {
        this.#label = pLabel;
        this.#icon = pIcon;
        this.#isHidden = pIsHidden;
        this.#isDummy = pIsDummy;
    }
}

class routerApplication
{   
    // Properties
    #auth = undefined;
    get auth() { return this.#auth;}
    #clientId = undefined;
    get clientId() { return this.#clientId;}
    #label = undefined;
    get label() { return this.#label;}
    #url = undefined;
    get url() { return this.#url;}
    completeUrl(pSuffix){this.#url += pSuffix;}
    #icon = undefined;
    get icon() { return this.#icon;}
    #isHidden = false;
    get isHidden() { return this.#isHidden;}
    hide(){this.#isHidden = true; }
    #isDummy = false;
    get isDummy() { return this.#isDummy;}

    // Identity
    constructor(pAuth, pClientId, pLabel, pUrl, pIcon = undefined, pIsHidden = false, pIsDummy = false)
    {
        this.#auth = pAuth;
        this.#clientId = pClientId;
        this.#label = pLabel;
        this.#url = pUrl;
        this.#icon = pIcon;
        this.#isHidden = pIsHidden;
        this.#isDummy = pIsDummy;
    }
}

export {routerGroup, routerApplication};
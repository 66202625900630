import keycloak from 'keycloak-js';
import Vue from 'vue';
import AccessDenied from '../views/AccessDenied.vue';

class serviceManager
{
    #services = [];
    #applications = undefined;
    get applications() { return this.#applications; }

    #currentService = undefined;

    registerService(pAuth, pOptions = undefined)
    {
        let service = undefined;

        switch (pAuth)
        {
            case "test":
                service = new testService(this, pOptions);
                break;
            case "keycloak":
                service = new keycloakService(this, pOptions);
                break;
            case "limesurvey":
                service = new limesurveyService(this, pOptions);
                break;
            case "limesurveyAdmin":
                service = new limesurveyAdminService(this, pOptions);
                break;
        }

        if(service == undefined)
        {
            throw "serviceManager.registerService() error: Unexpected pAuth parameter: <" + pAuth + ">";
        }

        this.#services.push(service);
    }

    getService(pAuth)
    {
        let service = this.#services.find(x => x.auth == pAuth);
        if(service == undefined)
        {
            throw "serviceManager.getService() error: Unexpected pAuth parameter: <" + pAuth + ">";
        }
        return service;
    }


    // Application
    getApplicationUrl(pClientId)
    {
        let application = this.#applications.find(x => x.clientId == pClientId);
        return application.url;
    }


    start(pClientId, pHandle)
    {
        let application = this.#applications.find(x => x.clientId == pClientId);
        let service = this.getService(application.auth);
        service.start(pClientId, pHandle);

        this.#currentService = service;
    }

    // User Related
    getUserName()
    {
        return this.#currentService.getUserName();
    }
    getUserFullName()
    {
        return this.#currentService.getUserFullName();
    }
    getUserMenuOptions()
    {
        return this.#currentService.getUserMenuOptions();
    }
    getCustomAttribute(pName)
    {
        return this.#currentService.getCustomAttribute(pName);
    }
    logout()
    {
        return this.#currentService.logout();
    }

    // Identity
    constructor(pApplications)
    {
        this.#applications = pApplications;
    }
}

class authUserMenuOption
{
    #label = undefined;
    get label() { return this.#label; }
    #icon = undefined;
    get icon() { return this.#icon; }
    #url = undefined;
    #onClickHandle = undefined;

    // action
    do()
    {
        if(this.#url != undefined)
        {
            window.location = this.#url;
        }
        else if(this.#onClickHandle != undefined)
        {
            this.#onClickHandle();
        }
        // Bah, rien à faire
    }

    // Identity
    constructor(pLabel, pIcon, pUrl, pOnClickHandle = undefined)
    {
        this.#label = pLabel;
        this.#icon = pIcon;
        this.#url = pUrl;
        this.#onClickHandle = pOnClickHandle;
    }
}

class authService
{
    #serviceManager = undefined;
    get serviceManager() { return this.#serviceManager; }

    #auth = undefined;
    get auth() { return this.#auth; }

    start(pOnAuthenticatedHandle)
    {
        throw "authService.start() error: method not implemented.";
    }

    getUserName()
    {
        throw "authService.getUserName() error: method not implemented.";
    }
    getUserFullName()
    {
        throw "authService.getUserName() error: method not implemented.";
    }
    getUserMenuOptions()
    {
        throw "authService.getUserName() error: method not implemented.";
    }
    getCustomAttribute(pName)
    {
        throw "authService.getCustomAttribute() error: method not implemented.";
    }
    getClientAccess(pClientId)
    {
        throw "authService.getClientAccess() error: method not implemented.";
    }
    logout(){
        throw "authService.logOut() error: method not implemented.";
    }

    buildMenuOption(pLabel, pIcon, pUrl, pHandle = undefined)
    {
        return new authUserMenuOption(pLabel, pIcon, pUrl, pHandle);
    }
    
    // Identity
    constructor(pServiceManager, pAuth)
    {
        this.#serviceManager = pServiceManager;
        this.#auth = pAuth;
    }
}

class keycloakService extends authService
{
    #options = undefined;
    #instance = undefined;

    start(pClientId, pOnAuthenticatedHandle)
    {
        this.#options.clientId = pClientId;
        this.#instance = new keycloak(this.#options);

        this.#instance.init({onLoad: this.#options.onLoad}).then((auth) => {
            if(!auth)
            {
                window.location.reload();
            }
            else
            {
                console.log(this.#instance);

                let clientAccess = this.getClientAccess(pClientId);
                if (clientAccess == undefined)
                {
                    new Vue({
                        render: h => h(AccessDenied)
                      }).$mount('#app');
                }
                else
                {
                    this.initializeApplications();
                    pOnAuthenticatedHandle();
                }
            }

            // Token Refresh
            setInterval(() => {
                this.#instance.updateToken(70).then((refreshed) => {
                    if(!refreshed)
                    {
                        // console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                    }
                }).catch(()=>{
                    console.log('Failed to refresh token');
                })
            }, 6000)
        }).catch((err) => {
            console.log("Authentication failed.", err);
        });
    }

    // Keycloak specific -> applications
    initializeApplications()
    {
        // Version crado -> A industrialiser
        this.serviceManager.applications.forEach(app => {

            let access = this.getClientAccess(app.clientId);
            if (access == undefined)
            {
                app.hide();
            }
            else
            {
                if (app.auth == "limesurvey")
                {
                    let limesurveyToken = this.getCustomAttribute("avenue_incident_survey_token");
                    if(limesurveyToken == undefined)
                    {
                        app.hide();
                    }
                    else
                    {
                        app.completeUrl(limesurveyToken);
                    }
                }
            }
        });

    }

    //User
    getUserName()
    {
        let parsed = this.#instance.tokenParsed;
        return parsed.given_name;
    }
    getUserFullName()
    {
        let parsed = this.#instance.tokenParsed;
        return parsed.name;
    }
    getUserMenuOptions()
    {
        return [
            this.buildMenuOption("Logout", "logout", undefined, () => { this.#instance.logout(); }),
            this.buildMenuOption("My Account", "settings", this.#instance.createAccountUrl())
        ];
    }
    getCustomAttribute(pName)
    {
        let parsed = this.#instance.tokenParsed;
        return parsed[pName];
    }
    getClientAccess(pClientId)
    {
        let parsed = this.#instance.tokenParsed;
        let resources = parsed["resource_access"];
        return resources[pClientId];
    }
    logout(){
        console.log(this.#instance);
        this.#instance.logout();
    }

    // Identity
    constructor(pServiceManager, pOptions)
    {
        super(pServiceManager, "keycloak");
        this.#options = pOptions;
    }
}

class testService extends authService
{
    getUserName()
    {
        return "Test Service User";
    }

    // Identity
    constructor(pServiceManager, pOptions)
    {
        super(pServiceManager, "test");
    }
}

class limesurveyService extends authService
{
    getUserName()
    {
        return "Limesurvey Service User";
    }

    // Identity
    constructor(pServiceManager, pOptions)
    {
        super(pServiceManager, "limesurvey");
    }
}

class limesurveyAdminService extends authService
{
    getUserName()
    {
        return "Limesurvey Service Admin User";
    }

    // Identity
    constructor(pServiceManager, pOptions)
    {
        super(pServiceManager, "limesurveyAdmin");
    }
}








export {serviceManager};
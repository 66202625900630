<template>
  <CEESAR-Application>

    <CEESAR-Header>
      <template slot="Left">
        <CEESAR-ApplicationTitle title="Portal" icon="home"/>
      </template>

      <template slot="Middle">
        
      </template>

      <template slot="Right">
        <CEESAR-Auth-UserDropDown contentAlign="Right"/>
      </template>

    </CEESAR-Header>

    <CEESAR-Main>

      <CEESAR-PortalView>

        <CEESAR-PortalGroup v-for="(group, iGroup) in groups" v-bind:key="iGroup"
                            :label="group.label" :icon="group.icon">

          <CEESAR-PortalLink v-for="(app, iApp) in group.applications" v-bind:key="iApp" 
                             :label="app.label" :icon="app.icon" :url="app.url"/>

        </CEESAR-PortalGroup>

      </CEESAR-PortalView>

    </CEESAR-Main>

    <CEESAR-Footer>
      <template slot="Left">
        <CEESAR-ApplicationLogo />
      </template>

    </CEESAR-Footer>
  </CEESAR-Application>
</template>

<script>
export default {
  name:"CEESAR-Portal",
  data(){
    return{
      accessToPortal: false,
      groups: [],

      accessToScenarioEditor: false,
      urlToScenarioEditor: "",

      accessToScenarioRanking: false,
      urlToScenarioRanking: ""
    };
  },
  async mounted(){
    this.$ceesar.design.applyTheme(document.querySelector('html'), "public");

    this.groups = this.$ceesar.router.getVisibleGroups();
  }
}
</script>
import {routerGroup} from '../../router/models/applications';

let groups = [];

let portalGroup = new routerGroup("Portal", undefined, true);
portalGroup.createApplication("keycloak",
    process.env.VUE_APP_KEYCLOAK_CLIENTID_PORTAL, "Portal",
    process.env.VUE_APP_URL_PORTAL, "home", true);
groups.push(portalGroup);

let scenariosGroup = new routerGroup("Scenarios", "movie");
scenariosGroup.createApplication("keycloak",
    process.env.VUE_APP_KEYCLOAK_CLIENTID_SCENARIO_EDITOR, "Editor",
    process.env.VUE_APP_URL_SCENARIO_EDITOR, "edit");
scenariosGroup.createApplication("keycloak", 
    process.env.VUE_APP_KEYCLOAK_CLIENTID_SCENARIO_RANKING, "AVENUE Ranking", 
    process.env.VUE_APP_URL_SCENARIO_RANKING, "thumbs_up_down");

scenariosGroup.createApplication("limesurvey",
    process.env.VUE_APP_ID_SURVEY_AVENUE_INCIDENT, "AVENUE Incident Survey", 
    process.env.VUE_APP_URL_SURVEY_AVENUE_INCIDENT, "question_answer");
scenariosGroup.createApplication("limesurveyAdmin",
    process.env.VUE_APP_ID_SURVEY_AVENUE_INCIDENT_ADMIN, "AVENUE Survey Administration",
    process.env.VUE_APP_URL_SURVEY_AVENUE_INCIDENT_ADMIN, "admin_panel_settings");
groups.push(scenariosGroup);






// Dummies
/*
for(let iGroup = 0; iGroup < 10; iGroup++)
{
    let dummyGroup = new routerGroup("Dummy Group " + iGroup, "help_outline", false, true);

    let nbDummies = 1 + Math.floor(Math.random() * 9);
    for(let iDummy = 0; iDummy < nbDummies; iDummy++)
    {
        dummyGroup.createApplication("test", "dummy-" + iGroup + "-" + iDummy, "Dummy " + iDummy, undefined, "help_center", false, true);
    }
    
    groups.push(dummyGroup);
}
*/



export default groups;
